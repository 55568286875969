<template>
  <div
    id="data-list-list-view"
    class="data-list-container"
    style="padding-bottom: 30px"
  >
    <vs-table
      ref="table"
      :sst="true"
      :total="totalDocs"
      :data="users"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="dataTableParams.limit"
      search
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow"
      >
        <vs-select
          placeholder="10"
          vs-multiple
          id="pageSizeSelect"
          autocomplete
          v-model="dataTableParams.limit"
          class="m-2"
        >
          <vs-select-item
            :key="index"
            :value="item"
            :text="item"
            v-for="(item, index) in limitOptions"
          />
        </vs-select>
        <vs-select
          placeholder="My Treatments"
          vs-multiple
          id="treatments_by"
          autocomplete
          v-model="dataTableParams.treatments_by"
          class="m-2"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in filterOptions"
          />
        </vs-select>
      </div>

      <template slot="thead">
        <vs-th sort-key="appointmentDate">Date</vs-th>
        <vs-th sort-key="clinicName">Clinic</vs-th>
        <vs-th sort-key="patientFullName">Patient</vs-th>
        <vs-th sort-key="nurseFullName">Nurse</vs-th>
        <vs-th sort-key="doctorFullName">Doctor</vs-th>
        <vs-th sort-key="treatment">Treatment</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th
          v-if="check_has_permission('viewDoctorTreatmentDetail')"
          size="25%"
          >Actions</vs-th
        >
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr]._id">{{
            moment(data[indextr].appointmentDate).format("DD/MM/YYYY")
          }}</vs-td>
          <vs-td :data="data[indextr].clinicName">{{
            data[indextr].clinicName
          }}</vs-td>
          <vs-td>{{ data[indextr].patientFullName }}</vs-td>
          <vs-td>{{
            data[indextr].nurseFullName !== "NA NA"
              ? data[indextr].nurseFullName
              : "N/A"
          }}</vs-td>
          <vs-td>{{
            data[indextr].doctorFullName !== "NA NA"
              ? data[indextr].doctorFullName
              : "N/A"
          }}</vs-td>
          <vs-td>{{ data[indextr].treatments }}</vs-td>
          <vs-td>{{ data[indextr].status }}</vs-td>
          <vs-td
            :data="data[indextr]._id"
            v-if="check_has_permission('viewDoctorTreatmentDetail')"
          >
            <vs-button
              type="border"
              size="small"
              @click="
                viewDetailHandler(data[indextr]._id, data[indextr].patientId)
              "
              icon-pack="feather"
              icon="icon-eye"
              class="mr-2"
            ></vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="m-2">
      <span class="mr-2">
        {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}
      </span>

      <div
        class="pagination-div"
        v-if="serverResponded"
        style="padding-top: 10px"
      >
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>

    <vs-popup
      class="holamundo"
      title="Treatment Details"
      :active.sync="popupActivo"
      button-close-hidden
    >
      <div>
        <h5>Overview</h5>
        <p>Date: {{ moment(modalData.createdAt).format("DD/MM/YYYY") }}</p>
        <!--<p>Nurse: {{nurseDetails.firstName}} {{nurseDetails.lastName}}</p>-->
        <p v-if="modalData.doctor">
          Doctor: {{ modalData.doctor.firstName }}
          {{ modalData.doctor.lastName }}
        </p>
        <p>
          Patient: {{ modalData.patient.firstName }}
          {{ modalData.patient.lastName }}
        </p>
        <a href="#">patient details</a>
      </div>
      <div>
        <h5>Treatment Plan</h5>
        <div
          v-for="(plan, key) in modalData.appointments.treatmentPlans"
          :key="key"
        >
          <p v-if="plan.product">
            {{ plan.product }}, {{ plan.area }}, {{ plan.amount }}ml
          </p>
        </div>
        <p>Pre - Treatment</p>
        <vs-row>
          <vs-col
            vs-w="3"
            v-for="(photo, key) in prePhotos"
            :key="key"
            class="imageClass"
          >
            <img :src="photo.photoUrl" width="100%" />
          </vs-col>
        </vs-row>
        <p>Post - Treatment</p>
        <vs-row>
          <vs-col
            vs-w="3"
            v-for="(photo, key) in postPhotos"
            :key="key"
            class="imageClass"
          >
            <img :src="photo.photoUrl" width="100%" />
          </vs-col>
        </vs-row>
        <!-- <div>
          <p>Procedure Notes</p>
        </div>-->
      </div>
      <div v-if="modalData.appointments.doctorTreatmentOutcome">
        <h5>Doctor Notes</h5>
        <p>
          Status: {{ modalData.appointments.doctorTreatmentOutcome.status }}
        </p>
        <p>{{ modalData.appointments.doctorTreatmentOutcome.note }}</p>
      </div>
      <div v-else>
        <h5>Doctor note unavailable</h5>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "treatment-history",
  components: {
    "v-select": vSelect,
  },
  props: ["nurseDetails"],
  data() {
    return {
      doctorId: null,
      modalData: {
        doctor: {},
        appointments: {},
        treatments: {},
        clinic: {},
        patient: {},
      },
      prePhotos: [],
      postPhotos: [],
      popupActivo: false,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "appointmentDate",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: "All",
        organizationId: "",
        treatments_by: "in-charge",
      },
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
      filterOptions: [
        {text: "My Treatments", value: "in-charge"},
        {text: "My NP & PAs", value: "supervised"},
        {text: "Clinic Treatments", value: "clinics"},
      ],
      treatments: [],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Active", label: "Active" },
        // { value: "Pending_Approval", label: "Pending Approval" },
        { value: "Disabled", label: "Disabled" },
      ],
      serverResponded: false,
    };
  },
  methods: {
    ...mapActions("doctor", ["fetchDoctorTreatmentHistory", "fetchDoctorRoleTreatmentHistory"]),
    moment(date) {
      return moment(date);
    },
    async getNurseTreatmentHistory() {
      this.$vs.loading();
      let datatableOptions = this.dataTableParams;
      let doctorId = this.doctorId;
      console.log(datatableOptions.treatments_by)
      try {
        const res = await this.fetchDoctorRoleTreatmentHistory({ doctorId, datatableOptions});
        console.log(res )
        this.users = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
        this.$vs.loading.close();
      }
      catch(e){
        this.users = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
        this.$vs.loading.close();
      }
      // this.fetchDoctorRoleTreatmentHistory({ doctorId, datatableOptions, treatments_by: this.treatments_by }).then(
      //   (res) => {
      //     self.users = res.data.data.docs;
      //     self.totalDocs = res.data.data.pagination.total;
      //     self.page = res.data.data.pagination.page;
      //     this.serverResponded = true;
      //   }
      // ).catch(e => {
      //   console.log(e)
      // });
    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNurseTreatmentHistory();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNurseTreatmentHistory();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNurseTreatmentHistory();
    },
    viewDetailHandler(id, patientId) {
      const loggedInUserType = this.$store.state.AppActiveUser.userType;
      const doctorId = this.$route.params.doctorId;
      if (loggedInUserType === "superAdmin") {
        this.$router.push({
          name: "SuperAdminDoctorTreatmentDetail",
          params: { doctorId: doctorId, patientId: patientId, checkinId: id },
        });
      } else if (loggedInUserType === "admin") {
        this.$router.push({
          name: "SubAdminDoctorTreatmentDetail",
          params: { doctorId: doctorId, patientId: patientId, checkinId: id },
        });
      } else if (
        loggedInUserType === "franchise" ||
        loggedInUserType === "subFranchise"
      ) {
        this.$router.push({
          name: "FranchiseDoctorTreatmentDetail",
          params: { doctorId: doctorId, patientId: patientId, checkinId: id },
        });
      } else if (loggedInUserType === "clinic") {
        this.$router.push({
          name: "ClinicDoctorTreatmentDetailView",
          params: { doctorId: doctorId, patientId: patientId, checkinId: id },
        });
      }
    },
    createNurseHandler() {
      this.$router.push({ name: "FranchiseNurseCreate" });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNurseTreatmentHistory();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNurseTreatmentHistory();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getNurseTreatmentHistory();
      }
    },
    "dataTableParams.treatments_by": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        console.log(newfilterVal, oldFilterVal)
        this.dataTableParams.page = 1;
        this.dataTableParams.treatments_by = newfilterVal;
        this.getNurseTreatmentHistory();
      }
    },
  },
  created() {
    this.doctorId = this.$route.params.doctorId;
    const role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    if (role && role.toUpperCase() === "org owner".toUpperCase()) {
      const organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      this.dataTableParams.organizationId = organizationId;
    }
    this.getNurseTreatmentHistory();
  },
};
</script>
<style>
.imageClass {
  padding: 5px;
  margin: 5px;
}
</style>
